<template>
    <div>
        <div class="head-img">
            <div class="head-img-box">
                <van-uploader :max-count="1"
                              v-model="fileList"
                              :multiple="false"
                              :before-read="beforeRead"
                              :after-read="afterRead"
                              :before-delete="delUploadImg"
                              upload-text="图片采集">
                </van-uploader>
            </div>
        </div>
        <van-form v-if="showForm" ref="form">
            <van-cell-group inset>
                <div class="form-box">
                    <van-field
                            v-model="visibleForm.orgName"
                            is-link
                            readonly
                            required
                            name="picker"
                            label="部门"
                            placeholder="点击部门"
                            @click="handleShow"
                            :rules="[{ required: true}]"
                    />
                    <ba-tree-picker
                            ref="treePicker"
                            :multiple='false'
                            @select-change="selectChange"
                            @confirm="handleConfirm"
                            title="选择部门"
                            :localdata="columns"
                            valueKey="id"
                            textKey="name"
                            childrenKey="subList"
                    />
                    <!-- 目的地 -->
                    <van-field
                            v-model="visibleForm.villageName"
                            readonly
                            required
                            name="picker"
                            label="场地名"
                            :rules="[{ required: true}]"
                    />
                    <!-- 姓名 -->
                    <van-field
                            v-model="visibleForm.name"
                            name="姓名"
                            required
                            placeholder="请输入姓名"
                            :rules="[{ required: true}]"
                    >
                        <!-- 使用label插槽 -->
                        <template #label>
                            <!-- 自定义样式label-flex -->
                            <div class="label-flex">
                                <span class="label-justify">姓名</span>
                            </div>
                        </template>
                    </van-field>
                    <!-- 用户编号 -->
                    <van-field
                            v-model="visibleForm.userNo"
                            name="用户编号"
                            label="用户编号"
                            placeholder="请输入用户编号"
                    >
                    </van-field>
                    <van-field
                            v-model="visibleForm.password"
                            type="password"
                            name="密码"
                            placeholder="密码"
                    >
                        <!-- 使用label插槽 -->
                        <template #label>
                            <!-- 自定义样式label-flex -->
                            <div class="label-flex">
                                <span class="label-justify">密码</span>
                            </div>
                        </template>
                    </van-field>
                    <van-field
                            name="性别"
                            required
                            placeholder="请选择性别"
                            :rules="[{ required: true}]"
                    >
                        <!-- 使用label插槽 -->
                        <template #label>
                            <!-- 自定义样式label-flex -->
                            <div class="label-flex">
                                <span class="label-justify">性别</span>
                            </div>
                        </template>
                        <template #input>
                            <van-radio-group v-model="visibleForm.sex" direction="horizontal">
                                <van-radio name="1">男</van-radio>
                                <van-radio name="2">女</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <!-- 手机号 -->
                    <van-field
                            v-model="visibleForm.phone"
                            name="手机号"
                            label="手机号"
                            required
                            placeholder="请输入手机号码"
                            type="digit"
                            :rules="[{ required: true}]"
                    />
                    <!-- 证件号码 -->
                    <van-field
                            v-model="visibleForm.idCardNo"
                            name="证件号"
                            label="证件号"
                            placeholder="请输入证件号"
                    />
                    <van-field
                            name="是否三方组织员工"
                            label="是否三方组织员工"
                            placeholder="请选择"
                            required
                            :rules="[{ required: true}]"
                    >
                        <template #input>
                            <van-radio-group v-model="visibleForm.is3rd" direction="horizontal">
                                <van-radio name="true">是</van-radio>
                                <van-radio name="false">否</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <!-- 地址 -->
                    <van-field
                            v-model="visibleForm.addr"
                            name="地址"
                            label="地址"
                            required
                            placeholder="请输入地址"
                            :rules="[{ required: true}]">

                        <!-- 使用label插槽 -->
                        <template #label>
                            <!-- 自定义样式label-flex -->
                            <div class="label-flex">
                                <span class="label-justify">地址</span>
                            </div>
                        </template>
                    </van-field>
                </div>
            </van-cell-group>
            <div class="div-btn2">
                <van-button class="btnn" block type="primary" @click="onSubmit">
                    提 交 申 请
                </van-button>
            </div>
        </van-form>
        <div class="bottom-style-text" v-if="showForm">
            <span class="p-text2">Copyright@2023 All Rights Reserved.</span>
        </div>
        <div v-if="showSuccess" class="info-box">
            <div class="info-box-icon">
                <van-icon size="75" color="#fff" name="success"/>
            </div>
            <p>操作成功</p>
            <div class="div-btn">
                <van-button class="van-btn" block type="warning" @click="onCancel">
                    关闭当前页面
                </van-button>
                <van-button block type="default" @click="onHomePage">
                    返回主页面
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Icon,
        Form,
        Field,
        Button,
        Popup,
        Cell,
        Toast,
        Uploader,
        CellGroup, ImagePreview, Radio, RadioGroup, Picker
    } from 'vant';
    import {getTreeData, uploadFile, userRequest} from "../api/interface";
    import Compressor from 'compressorjs';
    import baTreePicker from '../components/ba-tree-picker'

    export default {
        name: "ACRegistration",
        components: {
            [Button.name]: Button,
            [Form.name]: Form,
            [Icon.name]: Icon,
            [Field.name]: Field,
            [Radio.name]: Radio,
            [Popup.name]: Popup,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Uploader.name]: Uploader,
            [RadioGroup.name]: RadioGroup,
            [Picker.name]: Picker,
            baTreePicker
        },
        data() {
            return {
                visibleForm: {
                    avatar: "",
                    name: "",
                    idCardNo: "",
                    passNo: "",
                    phone: "",
                    sex: "",
                    userNo: "",
                    tag: "",
                    addr: "",
                    villageId: "",
                    villageName: "",
                    is3rd: "false",
                    password: "",
                    orgName: "",
                    orgId: ""
                },
                fileList: [],
                showSuccess: false,
                showForm: true,
                columns: [],
                arr: []

            };
        },
        created() {
            this.visibleForm.villageId = sessionStorage.getItem('villageId');
            this.visibleForm.villageName = sessionStorage.getItem('villageName');
        },
        methods: {
            handleShow() {
                this.columns = [];
                this.$refs.treePicker.showPicker();
                this.getTreeDataList();
            },
            getTreeDataList() {
                getTreeData({villageId: this.visibleForm.villageId, isUser: false}).then(res => {
                    this.columns = res.data.data;
                }).catch(err => {
                    //报错进这个
                    console.log(err)
                });
            },
            //监听选择（ids为数组）
            selectChange(ids, names) {
                console.log(ids, names);
                this.visibleForm.orgId = Number(ids);
            },
            handleConfirm(item) {
                item.sort(function (a, b) {
                    // 根据需要的排序逻辑返回比较结果
                    return a.level - b.level;
                });
                const names = item.map(({name}) => name);
                this.visibleForm.orgName = names.join('/');
            },
            onSubmit() {
                // 全局表单验证
                this.$refs.form.validate().then(() => {
                    let param = {
                        avatar: this.visibleForm.avatar || null,
                        idCardNo: this.visibleForm.idCardNo || null,
                        userNo: this.visibleForm.userNo || null,
                        name: this.visibleForm.name || null,
                        phone: this.visibleForm.phone || null,
                        sex: this.visibleForm.sex || null,
                        addr: this.visibleForm.addr || null,
                        villageId: this.visibleForm.villageId || null,
                        villageName: this.visibleForm.villageName || null,
                        is3rd: this.visibleForm.is3rd || null,
                        password: this.visibleForm.password || null,
                        orgId: this.visibleForm.orgId || null
                    }
                    userRequest(param).then((res) => {
                        if (res.data.code == "SUCCESS") {
                            Toast.success(res.data.message);
                            this.showForm = false;
                            this.showSuccess = true;
                        } else {
                            Toast.fail(res.data.message);
                        }
                    })
                }).catch(() => {
                    Toast({message: '必填项不能为空，提交失败！', duration: 2000})
                })

            },
            onCancel() {
                this.$router.push('/ACRecords')
            },
            onHomePage() {
                this.$router.push('/accessControl')
            },
            // 返回布尔值
            beforeRead(file) {
                if (file instanceof Array) {
                    //判断是否是数组
                    file.forEach((v) => {
                        this.checkFile(v);
                    });
                } else {
                    this.checkFile(file);
                }
                return true;
            },
            //图片类型检查
            checkFile(file) {
                const format = ["jpg", "png", "jpeg"];
                const index = file.name.lastIndexOf(".");
                const finalName = file.name.substr(index + 1);
                if (!format.includes(finalName.toLowerCase())) {
                    Toast("请上传" + format.join(",") + "格式图片");
                    return false;
                }
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                if (file instanceof Array) {
                    file.map((v) => {
                        v.status = "uploading";
                        v.message = "上传中...";
                        this.uploadImg(v);
                    });
                } else {
                    file.status = "uploading";
                    file.message = "上传中...";
                    this.uploadImg(file);
                }
            },
            //上传
            uploadImg(file) {
                let that = this;
                new Compressor(file.file, {
                    success(result) {
                        // Blob 转换为 File
                        const files = new window.File([result], file.name, {type: file.type});
                        const formData = new FormData();
                        formData.append('file', files);
                        uploadFile(formData).then(res => {
                            if (res.data.code == "SUCCESS") {
                                if (res.data.data) {
                                    if (file instanceof Array) {
                                        //判断是否是数组
                                        file.map((v, i) => {
                                            v.status = "success";
                                            v.message = "";
                                            v.url = res.data.data[i];
                                        });
                                    } else {
                                        file.status = "success";
                                        file.message = "";
                                        file.url = res.data.data;
                                        that.visibleForm.avatar = res.data.data;
                                    }
                                }
                            } else {
                                Toast({
                                    message: res.data.message,
                                    position: 'bottom',
                                    type: 'fail'
                                });
                            }
                        }).catch(err => { //报错进这个
                            console.log(err)
                        });
                    },
                    error(err) {
                        console.log(err.message);
                    },
                });
            },
            //文件上传
            // afterRead(file) {
            //     let content = file;
            //     let formData = new FormData();
            //     formData.append("file", content.file);
            //     uploadFile(formData).then(res => {
            //         if (res.data.code == "SUCCESS") {
            //             this.fileList = res.data.data;
            //             this.visibleForm.visitorAvatar = this.fileList;
            //         } else {
            //             Toast({
            //                 message: res.data.message,
            //                 position: 'bottom',
            //                 type: 'fail'
            //             });
            //         }
            //     }).catch(err => { //报错进这个
            //         console.log(err)
            //     });
            // },
            //删除
            delUploadImg(item) {
                this.fileList = this.fileList.filter((v) => v.url != item.url);
            },
        }
    }
</script>

<style scoped>
    >>> .van-uploader__upload {
        background-color: unset;
        width: 140px;
        height: 170px;
        margin: 0 auto;
    }

    >>> .van-uploader__preview {
        width: 140px;
        height: 170px;
        margin: 0 auto;
    }

    >>> .van-uploader__preview-image {
        width: 140px;
        height: 170px;
    }

    >>> .van-uploader__upload-icon {
        color: #EC7955;
        font-size: 50px;
    }

    >>> .van-uploader__upload-text {
        color: #EC7955;
        font-size: 13px;
    }

    .van-cell {
        padding: 18px;
    }

    .label-flex {
        display: flex;
        height: 20px;
        overflow: hidden;
    }

    .label-flex .label-justify {
        flex: 1;
        text-align: justify;
        text-align-last: justify;
        margin-right: 45px;
    }

    .label-flex .label-justify ::after {
        content: "";
        width: 100%;
        display: inline-block;
        height: 0;
    }

    .head-img {
        background-color: rgb(220 218 219);
        padding: 13px 0;
    }

    .head-img-box {
        margin: 0 auto;
        width: 150px;
        height: 170px;
        border: 1px solid #FD4A1B;
        padding: 10px;
    }

    /*.form-box {*/
    /*    height: 380px;*/
    /*}*/

    .div-btn2 {
        margin: 15px;
        display: flex
    }

    .div-btn2 .bbtn {
        margin: 10px;
    }

    .div-btn2 .btnn {
        background: linear-gradient(-30deg, #EC7955, #DC5040);
        border: none;
        margin: 10px;
    }

    .div-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 55px 20px 0 0;
    }

    .div-btn .van-button {
        font-weight: 500;
        margin: 10px;
    }

    .div-btn .van-btn {
        background: linear-gradient(-30deg, #e85744, #e86f50);
    }

    .info-box {
        display: flex;
        flex-direction: column;
        margin: 10px;
    }

    .info-box p {
        font-size: 18px;
        display: block;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        margin: 20px;
    }

    .info-box-icon {
        background-color: #05fa05;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 55px auto 0;
    }

    .bottom-style-text {
        height: 50px;
    }

    .bottom-style-text .p-text2 {
        font-family: 微软雅黑;
        font-size: 12px;
        color: #666666;
    }
</style>
